import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import Layout from "components/layout"
import ReactHtmlParser from "html-react-parser"
import axios from "axios"

const DraftNewsPage = () => {
  const [data, setData] = useState({
    title: "",
    body: "",
    status: "",
  })

  const FetchApi = async path => {
    const result = axios
      .get(path)
      .then(response => {
        return response
      })
      .catch(e => {
        return e.response
      })
    return result
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      const queryParams = new URLSearchParams(window.location.search)
      const newsId = queryParams.get("id")
      if (newsId && !isNaN(Number(newsId))) {
        const getAsyncPost = async () => {
          const baseUrl = process.env.STRAPI_URL
          const requestPath = `${baseUrl}/api/okulab-hp-posts/${newsId}`
          const result = await FetchApi(requestPath)
          if (result.status === 200) {
            const { data } = result.data
            setData({
              title: data?.attributes.title,
              body: data?.attributes.body,
              status: result.status,
            })
          } else {
            setData({
              title: "",
              body: "",
              status: result.status,
            })
          }
        }
        getAsyncPost()
      }
    }
  }, [])

  return (
    <Layout>
      <div className="article-page">
        <div className="entry-contents">
          {data.status === 200 && (
            <div className="entry-header">
              <h1 className="title">{data?.title ?? ""}</h1>
              <p className="published-date">下書き確認用</p>
            </div>
          )}

          {data.status !== 200 && (
            <div className="entry-header">
              <p className="published-date">
                指定されたIDの投稿が見つかりませんでした。idを確認の上、再度お試しください
              </p>
            </div>
          )}

          {data.status === 200 && (
              <div className="body">
              {ReactHtmlParser(data?.body ?? "")}
            </div>
          )}
        </div>

        <div className="footer">
          <Link to="/news" className="back-to-index">
            ニュース一覧
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default DraftNewsPage
